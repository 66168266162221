// src/pages/Home.tsx
import "./Home.scss";
import React, {useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Card,
} from "react-bootstrap";
import videojs from 'video.js'

const Home: React.FC = () => {
    useEffect(() => {
        videojs('main-video', {fluid: false, height: 540, width: 360, playsInline: true, enableSmoothSeeking: true});
    });
    return (
        <Container className="mt-2">
            <Row>
                <Col xs={12} sm={6}>
                    <Card className="content-card-sm content-card-md-tall content-card-sm-tall content-card-xs-tall">
                        <Card.Body>
                            <Card.Title as="h4" className="d-block d-sm-none">
                                <strong>Singularity Pressure Washing</strong><br/><br/>Your vision of clean, expertly
                                delivered
                            </Card.Title>
                            <Card.Title as="h4" className="d-none d-sm-block">
                                Your vision of clean, expertly delivered
                            </Card.Title>
                            <Card.Text>
                                <div>
                                    Do you want your home to be the envy of your family and friends?
                                    How long has it been since you had your property pressure washed? It's probably
                                    time, so <strong>call
                                    us today at <a href="tel:16613671477">(661) 367-1477</a></strong> and let us restore
                                    your home's natural glow!
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card
                        className="content-card-sm content-card-md-tall content-card-sm-tall content-card-xs-tall mt-2 mt-sm-0">
                        <Card.Body>
                            <Card.Text>
                                <div>
                                    <video
                                        id="main-video"
                                        className="video-js"
                                        controls
                                        preload="auto"
                                        data-setup="{}"
                                    >
                                        <source src="2024Q4FB.mov" type="video/mp4"/>
                                        <p className="vjs-no-js">
                                            To view this video please enable JavaScript, and consider upgrading to a
                                            web browser that
                                            <a href="https://videojs.com/html5-video-support/" target="_blank">supports
                                                HTML5 video</a>
                                        </p>
                                    </video>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <Card className="content-card-sm mt-2">
                        <Card.Body>
                            <Card.Title as="h4">Services</Card.Title>
                            <Card.Text>
                                <div>
                                    We offer a variety of outdoor cleaning services with flexible options and prices:
                                </div>
                                <ul>
                                    <li>Driveways</li>
                                    <li>Paths</li>
                                    <li>Pool Decks</li>
                                    <li>Exterior Walls</li>
                                    <li>Trash Cans</li>
                                    <li>Windows</li>
                                    <li>Much More!</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card className="content-card-sm mt-2">
                        <Card.Body>
                            <Card.Title as="h4">About Us</Card.Title>
                            <Card.Text>
                                <div>
                                    Singularity Pressure Washing is a professional pressure washing company servicing
                                    Santa Clarita, CA and surrounding areas. To get a free estimate, <strong>call
                                    us today at <a href="tel:16613671477">(661) 367-1477</a></strong>, or fill out the
                                    form below and we'll get back to you as soon as possible.
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <Card.Body>
                            <Card.Title as="h4">Contact Us For A Free Estimate</Card.Title>
                            <Card.Text>
                                <iframe id="markate-widget-contact-iframe" title="Contact Form"
                                        src="https://www.markate.com/public/widget/contact?id=ef649d699d8a5424c02ab1d34e0f2f97:63178:c81c4073"
                                        width="100%" height="980" scrolling="no" frameBorder="0"></iframe>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
